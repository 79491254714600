import { WithQuestion, WithRangeSelector } from './QuizQuestion';
import { IconName } from '@app/components/ui/icon/icon.component';
import { WithBlocks } from '@app/store/quiz/models/QuizScreenWithBlocks';

export enum StepType {
  startScreen = 'start-screen',
  singleChoice = 'single-choice',
  multiChoice = 'multi-choice',
  rangeSelector = 'range-selector',
  ageSelector = 'age-selector',
  fact = 'fact',
  birthdayInput = 'birthday-input',
  heightInput = 'height-input',
  weightInput = 'weight-input',
}

export interface AbstractStep {
  id: string;
  type: StepType;
  showProgress?: boolean;
}

interface WithWarning {
  warning?: {
    title: string;
    description?: string;
    icon?: IconName;
  };
}

interface WithImage {
  image?: string;
}
export function isStepWithImage(step: AbstractStep): step is AbstractStep & WithImage {
  return 'image' in step;
}

interface WithVariableWarning {
  warnings?: {
    [key: string]: {
      title: string;
      description?: string;
      icon?: IconName;
    };
  };
}

export interface SingleChoiceStep extends AbstractStep, WithQuestion, WithImage {
  type: StepType.singleChoice;
}
export function isSingleChoiceStep(step: AbstractStep): step is SingleChoiceStep {
  return step.type === StepType.singleChoice;
}

export interface MultiChoiceStep extends AbstractStep, WithQuestion, WithImage {
  type: StepType.multiChoice;
  invalidWarning?: {
    title: string;
    description?: string;
  };
}
export function isMultiChoiceStep(step: AbstractStep): step is MultiChoiceStep {
  return step.type === StepType.multiChoice;
}

export interface RangeSelectorStep
  extends AbstractStep,
    WithRangeSelector,
    WithVariableWarning,
    WithImage {
  type: StepType.rangeSelector;
  title: string;
}
export function isRangeSelectorStep(step: AbstractStep): step is RangeSelectorStep {
  return step.type === StepType.rangeSelector;
}

export interface StartScreenStep extends AbstractStep, WithBlocks {
  type: StepType.startScreen;
}
export function isStartScreenStep(step: AbstractStep): step is StartScreenStep {
  return step.type === StepType.startScreen;
}

export interface AgeSelectorStep extends AbstractStep, WithQuestion {
  type: StepType.ageSelector;
  rating?: number;
  downloads?: string;
  logo: string;
}
export function isAgeSelectorStep(step: AbstractStep): step is AgeSelectorStep {
  return step.type === StepType.ageSelector;
}

export interface FactStep extends AbstractStep, WithImage {
  type: StepType.fact;
  title: string;
  description?: string;
  image: string;
}
export function isFactStep(step: AbstractStep): step is FactStep {
  return step.type === StepType.fact;
}

export interface BirthdayInputStep extends AbstractStep, WithWarning, WithImage {
  type: StepType.birthdayInput;
  title: string;
}
export function isBirthdayInputStep(step: AbstractStep): step is BirthdayInputStep {
  return step.type === StepType.birthdayInput;
}

export interface HeightInputStep extends AbstractStep, WithWarning, WithImage {
  type: StepType.heightInput;
  title: string;
}
export function isHeightInputStep(step: AbstractStep): step is HeightInputStep {
  return step.type === StepType.heightInput;
}

export interface WeightInputStep extends AbstractStep, WithWarning, WithImage {
  type: StepType.weightInput;
  title: string;
}

export function isWeightInputStep(step: AbstractStep): step is WeightInputStep {
  return step.type === StepType.weightInput;
}
