import { Duration, ExperienceLevel, FlexibilityLevel, Gender, Trainer } from '@app/dataset';
import { AbstractStep } from '@app/store/quiz/models/QuizSteps';

export enum QuizQuestionType {
  gender = 'gender',
  challenge = 'challenge',
  experience = 'experience',
  flexibility = 'flexibility',
  duration = 'duration',
  trainer = 'trainer',
  yesNo = 'yesNo',
  days = 'days',
  intensity = 'intensity',
}

export type AnswerOption<T> = {
  id: T;
  label: string;
  image?: string;
};

interface RegularQuestion {
  options: AnswerOption<string>[];
}
export function isRegularQuestion(
  question: QuizQuestion
): question is BaseQuestion & RegularQuestion {
  return !Object.keys(question).includes('questionType');
}

interface GenderQuestion {
  questionType: QuizQuestionType.gender;
  options: AnswerOption<Gender>[];
}

interface ChangeableQuestion {
  questionType: QuizQuestionType.challenge;
  options: AnswerOption<number>[];
}

interface ExperienceQuestion {
  questionType: QuizQuestionType.experience;
  options: AnswerOption<ExperienceLevel>[];
}

interface FlexibilityQuestion {
  questionType: QuizQuestionType.flexibility;
  options: AnswerOption<FlexibilityLevel>[];
}

interface DurationQuestion {
  questionType: QuizQuestionType.duration;
  options: AnswerOption<Duration>[];
}

interface TrainerQuestion {
  questionType: QuizQuestionType.trainer;
  options: AnswerOption<string>[];
}

interface YesNoQuestion {
  questionType: QuizQuestionType.yesNo;
  options: AnswerOption<boolean>[];
}

type BaseQuestion = {
  title: string;
  description?: string;
  caption?: string;
};
type QuizQuestion = BaseQuestion &
  (
    | RegularQuestion
    | GenderQuestion
    | ChangeableQuestion
    | ExperienceQuestion
    | FlexibilityQuestion
    | DurationQuestion
    | TrainerQuestion
    | YesNoQuestion
  );

export interface WithQuestion {
  question: QuizQuestion;
}
export function isStepWithQuestion(step: AbstractStep): step is AbstractStep & WithQuestion {
  return 'question' in step;
}

export interface WithRangeSelector {
  range: {
    min: number;
    max: number;
    step: number;
  };
  tooltip?: string;
  defaultValue?: number;
  questionType?: QuizQuestionType.days | QuizQuestionType.intensity;
}
export function isStepWithRangeSelector(
  step: AbstractStep
): step is AbstractStep & WithRangeSelector {
  return Object.keys(step).includes('range');
}

export type DependedQuestionTypes = QuizQuestionType.duration | QuizQuestionType.trainer;
export type OptionSourceKeys = 'durations' | 'trainers';
export const dependenciesMap: Record<DependedQuestionTypes, OptionSourceKeys> = {
  [QuizQuestionType.duration]: 'durations',
  [QuizQuestionType.trainer]: 'trainers',
};

export type OptionSources = {
  durations: Duration[][];
  trainers: Trainer[][];
};

export type OptionsWithSource = {
  options: AnswerOption<string | number | boolean>[];
  source: OptionSources[keyof OptionSources];
};

export function isDependedQuestionType(
  questionType: QuizQuestionType
): questionType is DependedQuestionTypes {
  return questionType === QuizQuestionType.duration || questionType === QuizQuestionType.trainer;
}

export function isTrainerSource(source: OptionSources[keyof OptionSources]): source is Trainer[][] {
  return typeof source[0]?.[0] === 'object' && 'id' in source[0][0];
}

export function isDurationSource(
  source: OptionSources[keyof OptionSources]
): source is Duration[][] {
  return typeof source[0]?.[0] === 'number';
}
